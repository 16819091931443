
$(function() { 

    let moveColumn = function (table, from, to) {
        if(from == to){
            return;
        }
        var rows = $('tr', table);
        console.log('rows',rows)
        var cols;
        rows.each(function() {
            cols = $(this).children('th, td');
            if (from < to) {
                // if original position is less then new position insert col after i.e if dragging item up 
                cols.eq(from).detach().insertAfter(cols.eq(to));
            }else{
                cols.eq(from).detach().insertBefore(cols.eq(to));
                
            }
        });
      
    }
// Product sort
    $('.edit-cols').on('click', function(){
      $('#col-sort-modal').attr('data-sort-type', 'product')
      $('#close-sort-modal').attr('data-sort-type',"product")

        $('.order-columns').html('');
        let supplierId = $(this).attr('data-supplier-id')
            // console.log(supplierId)
        $("#col-sort-modal").attr('data-supplier', supplierId);
            $.ajax({
                type: "POST",
                url: getSupplierProductUrl,
                data: { supplier_id: supplierId, active:true }
              }).done(function( data ) {
           
            let pos = 1;
             data.map((product, index) => {
                 $('.order-columns').append(
                '<div class="card mb-2 bg-light" data-index="'+index+'" data-object-id="'+product.id+'" data-position="'+pos+'">\
                 <div class="card-body text-right d-flex align-items-center p-1">\
                 <i class="fas fa-arrows-alt col text-left"></i> <div class="col text-right text-nowrap">'+product.name+'</div>\
                 </div>\
               </div>')
               pos++
             })
          
     
  

          
              });
    });

// Supplier Sort

    $('.edit-supplier-sort').on('click', function(){
      $('#col-sort-modal').attr('data-sort-type', 'supplier')
      $('.order-columns').html('');
      $('#close-sort-modal').attr('data-sort-type',"supplier")
    
          // console.log(supplierId)
            $.ajax({
              type: "POST",
              url: getSuppliersUrl,
              data: {orderBy : "custom_sort"}
            }).done(function( data ) {
         
          let pos = 1;
           data.map((supplier, index) => {
               $('.order-columns').append(
              '<div class="card mb-2 bg-light" data-index="'+index+'" data-object-id="'+supplier.id+'" data-position="'+pos+'">\
               <div class="card-body text-right d-flex align-items-center p-1">\
               <i class="fas fa-arrows-alt col text-left"></i> <div class="col text-right text-nowrap">'+supplier.name+'</div>\
               </div>\
             </div>')
             pos++
           })
        
   


        
            });
  });

    $( function() {
        $( ".sortable" ).sortable({
        
      stop: function( event, ui ) {
       let supplier_id;
        let sortType = $(this).attr('data-sort-type');
        let colOrder = [];
        $.each( $('#col-sort-modal').children(), function( index, element ){
          colOrder.push($(element).attr('data-object-id'))
    
        });
        if(sortType == "product"){
          supplier_id = $('#col-sort-modal').attr('data-supplier');
          let newPos = 1;
          var tbl = $('.table_' + supplier_id);
          $.each( $('#col-sort-modal').children(), function( index, element ){
           $(element).attr('data-original-position', $(element).attr('data-position'));
            $(element).attr('data-position', newPos);
            newPos++;
            });
          // move column in the table to match the drag events
          moveColumn(tbl,   ui.item.attr('data-original-position') ,ui.item.attr('data-position'));
        }
    

        let data = sortType == "product" ? { supplier_id: supplier_id , newOrder: colOrder } : {  newOrder: colOrder }
// save new order to db 
        $.ajax({
            type: "POST",
            url: sortType == "product" ? setProductSortURL : setSuppliersSortURL,
            data: data
          }).done(function( data ) {
     

      
          });


                    
 
    
      }
    });
        $( ".sortable" ).disableSelection();
      } );


      $('#close-sort-modal').on('click',function(){
        if ($(this).attr('data-sort-type') == "supplier") {
          location.reload();
        }

      }
      )

})


