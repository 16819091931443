


$(document).on('keyup', '.product-input', function(evt){
 
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46){
     
            return false;
      
    }
        
    $(this).val(parseInt($(this).val(),10))
   
    return true;
})

$(document).on('keyup', '.product-input', function(evt){
    console.log($(this).val())
    if(!$(this).val()){
       
        $(this).val(0);
    

  }
   
})