
$('.populate').on("click",function(e){
var display  = $(this).attr('data-display');
var grouping  = $(this).data('grouping');
var date =  $("#populate_"+grouping+"_date").val();
console.log(grouping)
console.log(date)
$("#progressbar,  #modalbar").show();
  $.ajax({
      type: "POST",
      url: url,
      data: { grouping:grouping, date:date, _token:token,display:display }
    }).done(function( data ) {
console.log(data)
   $.each(data, function(client_id,valueObj){
     $.each(valueObj, function(prdct,qty){
      $("[data-name="+client_id +"_"+prdct+"]").trigger('click');
      $("[name="+client_id +"_"+prdct+"]").val(qty).trigger('input');
       if(qty > 0){
        //  $("[data-name="+client_id +"_"+prdct+"]").trigger('click');
        //  $("[name="+client_id +"_"+prdct+"]").val(qty).trigger('input');
         $("[name="+client_id +"_"+prdct+"]").css('background','#c4f9c4a8');
         setTimeout(() => {
          $("[name="+client_id +"_"+prdct+"]").css({'background':'none','transition': 'all 1s ease-in-out'});
         }, 2000);

       }


     });

   });
$("#progressbar,  #modalbar").hide();
    });








});
