$('.td-order-item').on( 'click',function(){
let loaded = $(this).attr('data-loaded');
let name = $(this).attr('data-name');
let productid = $(this).attr('data-productid');
let supplier = $(this).attr('data-grouping');

if (loaded == "true") {
    $(this).find('input').focus();
    console.log($(this).find('input').val())
    
}else{
    $(this).html(
        ' <input "\
         class="amount_'+productid+' test form-control col-12 '+supplier+' product-input "\
          style="padding:0px; text-align:center; height:auto;   border: none;  background: none;"\
           data-productid="'+productid+'" name="'+name+'" \
           type="number" value=""\
           min="0"> ')
           $(this).attr('data-loaded', "true");
           $(this).find('input').focus();
           $(this).find('input').val("0");
}


})

$(".td-order-item").on('keydown', 'input', function(e) { 
    var keyCode = e.keyCode || e.which || e.code; 
  
    if (keyCode == 9) { 
      e.preventDefault(); 
      if($(this).parent().is(':last-child')){
        $(this).parent().parent().next().closest('td').trigger('click');
        $(this).parent().parent().next().find('td:nth-child(2)').trigger('click');
       console.log() 
      }
      $(this).parent().next().trigger('click')
    } 
  });